import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  EMPTY,
  Observable,
  catchError,
  concatMap,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  of,
  pairwise,
  switchMap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';
import { ConnectFile, File3DStatus } from 'trimble-connect-workspace-api';
import { Host3dService } from '../connect-3d-ext/host-3d.service';
import { LicenseService } from '../license/license.service';
import { LicenseState } from '../license/license.state';
import { Logger, injectLogger } from '../logging/logger';
import { ProjectQuotaService } from '../quota/project-quota.service';
import {
  PointcloudAPIStatus,
  ScandataDisplayStatus,
  ScandataModel,
} from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import { ScandataState } from '../scandata/scandata.state';
import { ClearCurrentStation } from '../station/station.actions';
import { StationState } from '../station/station.state';
import { Role } from '../user/user.models';
import { UserState } from '../user/user.state';
import { IngestionSource } from '../utils/client-identification-headers';
import { ConnectFileService } from './connect-file.service';
import { ConnectIngestionService } from './connect-ingestion.service';
import { ConnectService } from './connect.service';
import { getExternalFileId, getFileId } from './external-file-id-utils';
import { ConnectFile3dStatus } from './models/connect-file-3d-status';
import { ConnectFileSelectEvent } from './models/connect-file-select-event';

@Injectable({
  providedIn: 'root',
})
export class Connect3dPanelService {
  private logger = injectLogger(Logger, 'Connect3dPanelService');

  constructor(
    private connectService: ConnectService,
    private connectFileService: ConnectFileService,
    private ingestionService: ConnectIngestionService,
    private projectQuotaService: ProjectQuotaService,
    private licenseService: LicenseService,
    private scandataService: ScandataService,
    private host3dService: Host3dService,
    private store: Store,
  ) {
    this.subscribeToDisplayedScans();
    this.subscribeToHiddenScans();
  }

  public subscribeToConnectEvents() {
    this.subscribeToIconClickAndTile();
    this.subscribeToIconClickAndShow();
  }

  private subscribeToIconClickAndTile() {
    this.getScanForFileClick()
      .pipe(
        filter(({ file }) => isNil(file.status) || file.status === 'unloaded'),
        map(({ file, scan }) => this.forceRetileIfFileWasUpdated(file, scan)),
        filter(({ scan }) => isNil(scan) || scan.status === PointcloudAPIStatus.Failed),
        mergeMap(({ file }) => this.checkAdmin(file)),
        mergeMap((file) => this.checkFileSize(file)),
        mergeMap((file) => this.checkQuota(file)),
        mergeMap((file) =>
          this.setup3dIcon(file.id, ConnectFile3dStatus.Tiling).pipe(
            switchMap(() => this.getFileDownloadUrl(file)),
            switchMap((downloadUrl) => this.createIngestion(file, downloadUrl)),
            switchMap((file) => this.setup3dIcon(file.id, ConnectFile3dStatus.ReadyToRefresh)),
          ),
        ),
      )
      .subscribe();
  }

  private subscribeToIconClickAndShow() {
    this.getScanForFileClick()
      .pipe(
        filter(({ file, scan }) => isDefined(scan) && !this.checkIfFileWasUpdated(file, scan)),
        mergeMap(({ file, scan }) =>
          this.setup3dIconForScan(file.id, scan as ScandataModel).pipe(
            map(() => ({ file, scan: scan as ScandataModel })),
          ),
        ),
        filter(({ scan }) => scan.status === PointcloudAPIStatus.Ready),
        mergeMap(({ file, scan }) => this.toggleScanDisplay(file, scan)),
      )
      .subscribe();
  }

  private getScanForFileClick() {
    return this.observeIconClicked().pipe(
      filter((fileEvent) => this.allowFileClick(fileEvent.file)),
      throttleTime(200),
      switchMap((fileEvent) =>
        this.setup3dIcon(fileEvent.file.id, ConnectFile3dStatus.CheckingStatus).pipe(
          map(() => fileEvent.file),
        ),
      ),
      concatMap((file) => this.checkLicense(file)),
      mergeMap((file) => this.updateConnectFile(file)),
      mergeMap((file) => this.getScan(file)),
    );
  }

  private observeIconClicked() {
    return from(this.connectService.getWorkspace()).pipe(
      switchMap((workspace) => workspace.event$),
      filter((workspaceEvent) => workspaceEvent.id === 'extension.fileViewClicked'),
      map((workspaceEvent) => workspaceEvent.data as ConnectFileSelectEvent),
      filter((fileEvent) => fileEvent.source.startsWith('3dviewer')),
    );
  }

  private allowFileClick(file: ConnectFile) {
    return (
      isNil(file.status) ||
      file.status === 'assimilationFailed' ||
      file.status === 'assimilationBusy' ||
      file.status === 'loaded' ||
      file.status === 'loadingFailed' ||
      file.status === 'unloaded'
    );
  }

  // TODO: Remove this after https://jira.trimble.tools/browse/TCWEB-7096
  private updateConnectFile(file: ConnectFile): Observable<ConnectFile> {
    return this.connectFileService.getFileVersions(file.id).pipe(
      map((fileVersions) => {
        const foundFile = fileVersions.find((x) => x.versionId === file.versionId);
        return {
          ...file,
          modifiedOn: foundFile?.modifiedOn ?? file.modifiedOn,
          versionId: foundFile?.versionId ?? file.versionId,
          revision: foundFile?.revision ?? file.revision,
        };
      }),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private getScan(file: ConnectFile) {
    const externalFileId = getExternalFileId(file) as string;
    const scan =
      this.store.selectSnapshot(ScandataState.getScanByExternalFileId(externalFileId)) ??
      this.store.selectSnapshot(ScandataState.getScanByExternalFileId(file.id));

    const scanIsReadyOrFailed =
      scan?.status === PointcloudAPIStatus.Ready || scan?.status === PointcloudAPIStatus.Failed;

    return scanIsReadyOrFailed
      ? of({ file, scan })
      : this.ingestionService.getScan(file).pipe(
          map((scan) => ({ file, scan })),
          catchError((err) => this.handleError(file, err)),
        );
  }

  private getFileDownloadUrl(file: ConnectFile) {
    return this.connectService.getFileDownloadUrl(file).pipe(
      map((download) => download.url),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private getFile3dStatus(status: ConnectFile3dStatus): File3DStatus {
    switch (status) {
      case ConnectFile3dStatus.CheckingStatus:
        return 'loadingWithoutCancel';
      case ConnectFile3dStatus.ReadyToRefresh:
        return 'assimilationBusy';
      case ConnectFile3dStatus.Tiling:
        return 'assimilating';
      case ConnectFile3dStatus.FileSizeExceeded:
      case ConnectFile3dStatus.IngestionError:
      case ConnectFile3dStatus.QuotaExceeded:
      case ConnectFile3dStatus.TilingError:
      case ConnectFile3dStatus.NotAdmin:
      case ConnectFile3dStatus.NoActiveLicense:
        return 'assimilationFailed';
      case ConnectFile3dStatus.Loading:
        return 'loadingWithoutCancel';
      case ConnectFile3dStatus.LoadingFailed:
        return 'loadingFailed';
      case ConnectFile3dStatus.Loaded:
        return 'loaded';
      case ConnectFile3dStatus.Unloading:
        return 'loadingWithoutCancel';
      case ConnectFile3dStatus.Unloaded:
        return 'unloaded';
    }
  }

  private getFileStatusMessage(status: ConnectFile3dStatus) {
    switch (status) {
      case ConnectFile3dStatus.CheckingStatus:
        return 'Checking status...';
      case ConnectFile3dStatus.FileSizeExceeded:
        return 'Files larger than 50GB are not supported.';
      case ConnectFile3dStatus.IngestionError:
        return 'Error. Click to refresh.';
      case ConnectFile3dStatus.QuotaExceeded:
        return 'Quota will be exceeded.';
      case ConnectFile3dStatus.ReadyToRefresh:
        return 'Busy tiling. Click to refresh.';
      case ConnectFile3dStatus.Tiling:
        return 'Tiling...';
      case ConnectFile3dStatus.TilingError:
        return 'Tiling failed...';
      case ConnectFile3dStatus.Loading:
        return '';
      case ConnectFile3dStatus.LoadingFailed:
        return '';
      case ConnectFile3dStatus.Loaded:
        return '';
      case ConnectFile3dStatus.NotAdmin:
        return 'Only admin users can tile this file.';
      case ConnectFile3dStatus.NoActiveLicense:
        return 'No active license for project.';
      case ConnectFile3dStatus.Unloading:
        return '';
      case ConnectFile3dStatus.Unloaded:
        return '';
    }
  }

  private getLicenses(file: ConnectFile) {
    return this.licenseService
      .getLicenses()
      .pipe(
        catchError(() =>
          this.setup3dIcon(file.id, ConnectFile3dStatus.NoActiveLicense).pipe(
            switchMap(() => EMPTY),
          ),
        ),
      );
  }

  private createIngestion(file: ConnectFile, downloadUrl: string) {
    return this.ingestionService.createIngestion(file, downloadUrl, IngestionSource.Connect3D).pipe(
      map(() => file),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private checkAdmin(file: ConnectFile) {
    return this.store
      .selectOnce(UserState.userRole)
      .pipe(
        switchMap((role) =>
          role === Role.Admin
            ? of(file)
            : this.setup3dIcon(file.id, ConnectFile3dStatus.NotAdmin).pipe(switchMap(() => EMPTY)),
        ),
      );
  }

  private checkFileSize(file: ConnectFile) {
    return (file.size ?? 0) <= 50 * 1024 ** 3
      ? of(file)
      : this.setup3dIcon(file.id, ConnectFile3dStatus.FileSizeExceeded).pipe(
          switchMap(() => EMPTY),
        );
  }

  private checkLicense(file: ConnectFile) {
    return this.store.selectOnce(LicenseState.licenses).pipe(
      switchMap((licenses) =>
        isDefined(licenses)
          ? this.store.selectOnce(LicenseState.hasActiveLicense)
          : this.getLicenses(file),
      ),
      switchMap((hasActiveLicense) =>
        hasActiveLicense
          ? of(file)
          : this.setup3dIcon(file.id, ConnectFile3dStatus.NoActiveLicense).pipe(
              switchMap(() => EMPTY),
            ),
      ),
    );
  }

  private checkQuota(file: ConnectFile) {
    return this.projectQuotaService
      .quotaExceeded(file.size ?? 0)
      .pipe(
        switchMap((quotaExceeded) =>
          quotaExceeded
            ? this.setup3dIcon(file.id, ConnectFile3dStatus.QuotaExceeded).pipe(
                switchMap(() => EMPTY),
              )
            : of(file),
        ),
      );
  }

  private forceRetileIfFileWasUpdated(file: ConnectFile, scan?: ScandataModel) {
    return isDefined(scan) && this.checkIfFileWasUpdated(file, scan)
      ? { file, scan: undefined }
      : { file, scan };
  }

  private checkIfFileWasUpdated(file: ConnectFile, scan: ScandataModel) {
    const fileModifiedOn = new Date(file.modifiedOn ?? 0);
    const scanUploadedDate = new Date(scan.uploadedDate ?? 0);
    return fileModifiedOn > scanUploadedDate && (file.revision ?? 1) > 1;
  }

  private setup3dIcon(connectFileId: string, status: ConnectFile3dStatus) {
    return from(this.connectService.getWorkspace()).pipe(
      switchMap((workspace) =>
        from(
          workspace.api.ui.addCustomFileAction([
            {
              fileId: connectFileId,
              fileStatusIcon: {
                fileStatus: this.getFile3dStatus(status),
                fileStatusMessage: this.getFileStatusMessage(status),
              },
            },
          ]),
        ),
      ),
    );
  }

  private setup3dIconForScan(connectFileId: string, scan: ScandataModel) {
    switch (scan.status) {
      case PointcloudAPIStatus.Initializing:
      case PointcloudAPIStatus.InProgress:
        return this.setup3dIcon(connectFileId, ConnectFile3dStatus.ReadyToRefresh);
      case PointcloudAPIStatus.Ready:
        return of(true); // Icon is setup in toggleScanDisplay
      case PointcloudAPIStatus.Failed:
        return this.setup3dIcon(connectFileId, ConnectFile3dStatus.TilingError);
    }
  }

  private setIconToUnloaded(scan: ScandataModel, allScans: ScandataModel[]) {
    const externalFileId = scan.externalFileId ?? '';
    const fileId = getFileId(externalFileId) ?? '';

    if (fileId === '') return of(false);

    const filteredScans = allScans.filter((x) => x.externalFileId?.includes(fileId));
    const scanIsStillShown = filteredScans.some((x) => x.showInScene);
    return scanIsStillShown ? of(true) : this.setup3dIcon(fileId, ConnectFile3dStatus.Unloaded);
  }

  private setupIconByExternalFileId(scan: ScandataModel, status: ConnectFile3dStatus) {
    const externalFileId = scan.externalFileId ?? '';
    const fileId = getFileId(externalFileId) ?? '';

    if (fileId === '') return of(false);

    return this.setup3dIcon(fileId, status);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(file: ConnectFile, err: any) {
    this.logger.error(`3d Ingesting error (${file.name})`, {}, err);
    return this.setup3dIcon(file.id, ConnectFile3dStatus.IngestionError).pipe(
      switchMap(() => EMPTY),
    );
  }

  private toggleScanDisplay(file: ConnectFile, scan: ScandataModel) {
    const shouldHide = file.status === 'loaded';
    const status = shouldHide ? ConnectFile3dStatus.Unloading : ConnectFile3dStatus.Loading;

    const action$ = shouldHide
      ? this.hideAllScansAndStations(scan)
      : this.host3dService.showScan(scan);

    return this.setup3dIcon(file.id, status).pipe(switchMap(() => action$));
  }

  private subscribeToDisplayedScans() {
    const firstDisplayStatus$ = this.getFirstStatusChangeForTiledScans(
      ScandataDisplayStatus.AwaitingDisplay,
    );

    const hasExternalFileId$ = firstDisplayStatus$.pipe(
      map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
    );

    const needsExternalFileId$ = firstDisplayStatus$.pipe(
      map((scans) => scans.filter((x) => isNil(x.externalFileId))),
      filter((scans) => scans.length > 0),
      switchMap((scans) => forkJoin(scans.map((x) => this.scandataService.getScandataModel(x.id)))),
      map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
    );

    merge(hasExternalFileId$, needsExternalFileId$)
      .pipe(
        filter((scans) => scans.length > 0),
        concatMap((scans) =>
          forkJoin(
            scans.map((scan) => this.setupIconByExternalFileId(scan, ConnectFile3dStatus.Loaded)),
          ),
        ),
      )
      .subscribe();
  }

  private subscribeToHiddenScans() {
    this.getFirstStatusChangeForTiledScans(ScandataDisplayStatus.Hidden)
      .pipe(
        map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
        filter((scans) => scans.length > 0),
        withLatestFrom(this.store.select(ScandataState.scandata)),
        concatMap(([hiddenScans, allScans]) =>
          forkJoin(hiddenScans.map((scan) => this.setIconToUnloaded(scan, allScans))),
        ),
      )
      .subscribe();
  }

  private getFirstStatusChangeForTiledScans(status: ScandataDisplayStatus) {
    const tiledScans$ = this.store.select(ScandataState.scandata).pipe(
      map((scans) => scans.filter((x) => x.status === PointcloudAPIStatus.Ready)),
      filter((scans) => scans.length > 0),
    );

    const firstStatus$ = tiledScans$.pipe(
      map((scans) => scans.filter((x) => x.displayStatus === status)),
      pairwise(),
      map(([prev, curr]) =>
        isNil(prev) ? curr : curr.filter((c) => isNil(prev.find((p) => p.id === c.id))),
      ),
    );

    return firstStatus$;
  }

  private hideAllScansAndStations(scan: ScandataModel) {
    const externalFileId = scan.externalFileId as string;
    const fileId = getFileId(externalFileId) as string;
    const displayedScans = this.store
      .selectSnapshot(ScandataState.scandata)
      .filter(
        (x) =>
          x.externalFileId?.includes(fileId) &&
          (x.displayStatus === ScandataDisplayStatus.AwaitingDisplay ||
            x.displayStatus === ScandataDisplayStatus.Displayed),
      );

    return this.host3dService.hideScans(displayedScans).pipe(
      switchMap(() => this.store.selectOnce(StationState.currentStation)),
      switchMap((currentStation) =>
        isDefined(currentStation) ? this.store.dispatch(new ClearCurrentStation()) : of(true),
      ),
    );
  }
}
